// A React component to wrap our routes and pass the
// properties into the router page components
import { useEffect, useState } from 'react';
import { Route, Routes, useSearchParams, useParams } from 'react-router-dom';
import EventData from '../../data/demoEvents.json';
import Home from '../../pages/home';
import Traits from '../../pages/traits';
import Wallet from '../../pages/wallet';
import Settings from '../../pages/settings';
import EventSingle from '../../pages/eventSingle';
import TokenSingle from '../../pages/tokenSingle';
import axios from 'axios';
import qs from 'qs';

export default function RouterWrapper() {
  let [searchParams, setSearchParams] = useSearchParams('');
  let router = useParams();
  let [eventData, setEventData] = useState<any>(EventData);
  let [nftData, setNftData] = useState<any>({ traits: {} });

  let [experienceTokens, setExperienceTokens] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!searchParams.get('uuid') || searchParams.get('uuid')?.length !== 36) {
      // if the query string params aren't a proper GUID
      // just use the test data
      setNftData(require('../../data/data.json').data);
      setIsLoading(false);
    } else {
      axios
        .post(
          `https://garments.mmerch.net/uuidLookup`,
          qs.stringify({ uuid: searchParams.get('uuid') })
        )
        .then((response) => {
          setNftData(response.data.data);
          setIsLoading(false);
        });
    }
  }, [searchParams]);

  useEffect(() => {
    //FIXME: do a valid wallet check
    if (nftData.wallet?.length === 42) {
      axios
        .post(
          `https://garments.mmerch.net/experienceTokens`,
          qs.stringify({ ww: nftData.wallet })
        )
        .then((response) => {
          setExperienceTokens(response.data.experienceTokens);
        });
    }
  }, [nftData]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            nftData={nftData}
            isLoading={isLoading}
            experienceTokens={experienceTokens}
          />
        }
      />
      <Route path="/traits" element={<Traits nftData={nftData} />} />
      <Route
        path="/wallet"
        element={<Wallet experienceTokens={experienceTokens} />}
      />
      <Route path="/settings" element={<Settings />} />
      <Route
        path="/eventSingle/:slug"
        element={<EventSingle eventData={eventData} nftData={nftData} />}
      />
      <Route
        path="/tokenSingle/:id"
        element={<TokenSingle experienceTokens={experienceTokens} />}
      />
    </Routes>
  );
}
