import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import Button from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import ArrowIcon from '../../components/Icons/ArrowIcon';
import QRCode from '../../components/Icons/QRCode';
import Modal from '../../components/Modal/Modal';
import RunGsap from '../../helpers/RunGsap';
import ButtonContainer from '../../components/ButtonContainer/ButtonContainer';
// import EventImage from '../../assets/img/wwmoma.png';
import EventImage from '../../assets/img/swarovski_event.png';

function EventSingle(props: { nftData: any; eventData: any }) {
  const { slug } = useParams<{ slug: string }>();
  const [ModalOpenQR, setModalOpenQR] = useState(false);
  const [ModalOpenCheckin, setModalOpenCheckin] = useState(false);
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [text, setText] = useState('LOADING...');
  const [title, setTitle] = useState('Check-in');
  const [isLoading, setIsLoading] = useState(false);
  const [hasTicket, setHasTicket] = useState(false);
  let nftData = props.nftData;
  const eventData = props.eventData;
  const hoodieNumber = nftData.number;

  console.log('nftData', nftData.number);

  // To test API calls
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds

  // Filter eventData by slug
  const findEventBySlug = (eventData: any[], slug: string) => {
    return eventData.find((event) => event.path === slug);
  };

  const matchingEvent = findEventBySlug(eventData, slug || '');

  // To test API calls
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);

  // To show ticket countdown timer
  // const formatTime = (seconds: number) => {
  //   const h = Math.floor(seconds / 3600);
  //   const m = Math.floor((seconds % 3600) / 60);
  //   const s = seconds % 60;
  //   return `${h.toString().padStart(2, '0')}:${m
  //     .toString()
  //     .padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  // };

  const emailSubject = 'I’m%20going%20to%20be%20in%20Marfa!';
  const emailBody = `Hi,%20mmERCH!\n \nIf%20there%20are%20spots%20left,%20I’d%20love%20to%20be%20added%20to%20the%20list%20for%20the%20private%20tour%20of%20the%20Chinati%20Foundation%20on%20November%2016,%202024!
${hoodieNumber ? `%0A%0AHoodie%20token%20${hoodieNumber}` : ''}`;
  const emailLink = `mailto:info@mmerch.xyz?subject=${emailSubject}&body=${emailBody}`;

  const getPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition(position);
        console.log('position', position);
      },
      (error) => {
        if (error.code === 0) {
          setTitle('Unknown error');
          setText(
            'It seems your location is unavailable. (1) Try again, (2) Check settings, (3) try a different browser or (4) send us a mail.'
          );
        } else if (error.code === error.PERMISSION_DENIED || error.code === 1) {
          setTitle('Denied');
          setText(
            'It seems you (previously) denied. (1) try a different browser or (2) send us a mail.'
          );
        } else if (error.code === 2) {
          setTitle('Position Unavailable');
          setText(
            'It seems your location is unavailable. (1) Try again, (2) Check settings, (3) try a different browser or (4) send us a mail.'
          );
        } else if (error.code === 3) {
          setTitle('Timeout');
          setText('That took too long. (1) Try again or (2) send us a mail.');
        }
        console.log('error', error);
      },
      {
        timeout: 10000, // 10 seconds timeout
        maximumAge: 60000, // accept only a position not older than 60 seconds
        enableHighAccuracy: true, // high accuracy
      }
    );
  };

  // Below btn an explainer text for the user
  // click opens request + button shows loading
  // when location is found, data send off to server
  // server responds and button turns into success
  // a modal is opened with a show of token, or error message

  const handleCloseModalQR = () => {
    setModalOpenQR(false);
  };

  const handleOpenModalCheckin = () => {
    getPosition();
    // setModalOpenCheckin(true);
    // if (!hasTicket) {
    //   setIsLoading(true);
    //   setTimeout(() => {
    //     setModalOpenQR(true);
    //     setIsLoading(false);
    //     setHasTicket(true);
    //   }, 1500);
    // } else {
    //   setModalOpenQR(true);
    // }
  };

  const handleCloseModalCheckin = () => {
    setModalOpenCheckin(false);
  };

  console.log('matchingEvent', matchingEvent.link.linkShow);

  return (
    <div className="page page-Token">
      <RunGsap />
      <Module hasEvenCorners noTopPadding>
        <ModuleImage noTopPadding aspectRatio="13:16">
          <img src={matchingEvent.image} alt="nft" loading="lazy" />
        </ModuleImage>
        <ModuleText>
          <div className="module-Title animateToken">
            <h1>{matchingEvent.name}</h1>
          </div>
          {(matchingEvent.date || matchingEvent.location) && (
            <div className="module-Text_List">
              <p className="typeSmall normal animateToken">
                {matchingEvent.date}
                {matchingEvent.location && (
                  <>
                    <br />
                    {matchingEvent.location}
                  </>
                )}
              </p>
              <p className="typeNormal normal animateToken">
                {matchingEvent.description}
              </p>
            </div>
          )}
        </ModuleText>
        {/* {matchingEvent.link.linkShow === true && (
          <div className="module-tinyLink">
            <a
              href={matchingEvent.link.linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              {matchingEvent.link.linkText}
            </a>
            <div className="icon">
              <IconArrow direction="link" />
            </div>
          </div>
        )} */}
        <ButtonContainer stretch vertical>
          {matchingEvent.buttonVariation === 'RSVP' && (
            <Button className="green" stretchHor exitingPath={emailLink}>
              <p>{matchingEvent.buttonText}</p>
            </Button>
          )}
          {/* <Button
            className="green"
            stretchHor
            hasLoading
            onClick={handleOpenModalCheckin}
          >
            {hasTicket && (
              // <p>{isLoading ? 'Loading...' : 'Get ticket'}</p>
              <p>Show ticket</p>
            )}
            {!hasTicket && !isLoading && <p>Generate ticket</p>}
            {!hasTicket && isLoading && (
              <p className="animationPulse">Generating</p>
            )}
          </Button> */}

          {/* <span>
            <i>
              Please be sure to wear your hoodie to the event in order to
              receive an Experience Token (XP).
            </i>
          </span> */}
          {/* <Button className="green" stretchHor onClick={handleOpenModalCheckin}>
            <p>Check-in</p>
          </Button>
          <span className="typeSmall normal animateToken">
            Checking-in will prompt permission to share your location. We do not
            see or store your location. We only get a confirmation, that will be
            used for airdrops.
          </span> */}
        </ButtonContainer>
      </Module>
      <ButtonContainer alignLeft>
        <Button className="green" back path="/">
          <div className="icon">
            <IconArrow direction="left" />
          </div>
        </Button>
      </ButtonContainer>
      {/* <Modal
        title="Your ticket"
        isOpen={ModalOpenQR}
        onClose={handleCloseModalQR}
        btnTitle="close"
        hasCloseBtn
      >
        <div className="QRCode">
          <QRCode />
        </div>
        <div className="QRCode_Text">
          <p>
            Your ticket is valid for:
            <br />
            {formatTime(timeLeft)}
          </p>
        </div>
      </Modal> */}
      {/* <Modal
        title={title}
        isOpen={ModalOpenCheckin}
        onClose={handleCloseModalCheckin}
        btnTitle="check-in"
        // hasCloseBtn
        // hasCancel
      >
        {position && (
          <>
            <p>
              We found your position:
              <br />
              <br />
              LAT: {position.coords.latitude}
              <br />
              LONG: {position.coords.longitude}
              <br />
              <br />
              With an accuracy of {position.coords.accuracy} meters.
            </p>
            <Button
              className="green"
              stretchHor
              exitingPath={`https://www.google.com/maps/search/?api=1&query=${position.coords.latitude},${position.coords.longitude}`}
            >
              <p>Show my location</p>
            </Button>
          </>
        )}
        {!position && <p>{text}</p>}
      </Modal> */}
      <div className="page-Bottom" />
    </div>
  );
}

export default EventSingle;
